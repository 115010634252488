<script setup lang="ts">
import Tracker, { type ClickCommunityContentProps } from '~/libs/Tracker';

import CustomLink from '~/components/common/CustomLink.vue';

const { isDesktop } = useMobile();

const route = useRoute();
const runtime = useRuntimeConfig();

const navToDetail = () => {
  const eventParams: ClickCommunityContentProps = {
    boardId: '',
    boardName: '',
    boardTypeCd: '',
    boardTypeName: '',
    boardContentsId: '',
    boardContentsTitle: '',
    boardContentsBestYn: 'N',
    mustReadYn: 'N',
    viewCount: '',
    commentCount: '',
    regId: '',
    followingYn: 'N',
    pageName: 'CLASS',
    location: 'CURATION_COMMUNITY_3',
    relatedContentsId: '',
    relatedTitle: null,
    likeCount: '',
    path: route.fullPath,
    url: `${runtime.public.frontUrl}${route.fullPath}`,
  };
  Tracker['Click Community Content'](eventParams);
};
</script>

<template>
  <CustomLink
    class="relative cursor-pointer w-full md:w-[283px]"
    to="https://weolbu.stibee.com"
    target="_blank"
    @click="navToDetail()">
    <div class="relative cursor-pointer w-full md:w-[283px]">
      <div v-if="!isDesktop" class="w-full h-[120px] flex justify-end rounded-[4px] bg-[#feffc8]">
        <img
          class="w-auto h-full bg-transparent md:w-full"
          src="~/assets/images/community_wb_letter_banner_m.webp"
          alt="월부 레터" />
      </div>
      <img
        v-else
        class="w-auto h-full bg-transparent md:w-full"
        src="~/assets/images/community_wb_letter_banner_pc.webp"
        alt="월부 레터" />

      <div v-if="!isDesktop" class="absolute top-[22px] left-[20px] text-black">
        <p class="text-[16px] font-medium leading-[20px] tracking-[-0.48px] mb-[8px] keep-all">
          월부레터 구독하고 <br />매주 월,목 <span class="font-bold">부자되는 칼럼</span>받자!
        </p>

        <div
          class="rounded-[4px] border border-black bg-[rgba(254,255,221,0.8)] backdrop-blur-[2px] flex items-center px-[36px] py-[8px] pl-[40px] gap-[4px] w-[162px]">
          <p class="text-black text-[12px] font-bold leading-normal tracking-[-0.36px]">무료 구독하기</p>
          <ArrowRight class="flex" />
        </div>
      </div>
    </div>
  </CustomLink>
</template>

<style lang="scss" scoped>
.keep-all {
  word-break: keep-all;
}
</style>
